import React, {Component} from "react"
import Image800 from "../components/Image800"
import {Power3} from "gsap/TweenLite"
import {TimelineMax} from "gsap/TimelineMax"
import {SHOWME} from "../constants"
import "../styles/pages/photos.scss"
import Menu from "../components/Menu"
import {randomIntFromInterval} from "../utils"
import {TweenMax} from "gsap/TweenMax"
import {Draggable} from "gsap/Draggable"
import ShowMe from "../components/ShowMe"

class Photos extends Component {
  
  state = {
    images: SHOWME["random"],
    which: "random"
  }
  
  _garbageCollection = () => {
    if (this.draggable) {
      this.draggable.forEach((el => el.kill()))
    }
    
    if (this.imagesTimeline)
      this.imagesTimeline.kill()
  }
  
  componentDidUpdate() {
    this.createTimeline()
  }
  
  componentDidMount() {
    this.createTimeline()
  }
  
  componentWillUnmount() {
    this._garbageCollection()
  }
  
  createTimeline() {
    
    this._garbageCollection()
    
    this.draggable = Draggable.create(".photo--instance", {type: "x,y"})
    
    const element = document.querySelectorAll(".photo--instance")
    
    // const imagesTimeline = new TimelineMax({
    this.imagesTimeline = new TimelineMax({
      paused: true,
      delay: 0.5
    })
    
    const offset = 400
    const height = window.innerHeight
    const width = window.innerWidth
    const from = {
      visibility: "hidden",
      cycle: {
        y: () => (randomIntFromInterval(0, height - offset)),
        x: () => (randomIntFromInterval(0, width - offset))
      }
    }
    const to = {
      visibility: "visible",
      ease: Power3.easeOut
    }
    
    this.imagesTimeline.add(TweenMax.staggerFromTo(element, 0.1, from, to, 0.1))
    this.imagesTimeline.play()
  }
  
  changeTo = (which) => {
    // const images = []
    // for (let i = 0; i < 7; i++) {
    //   images.push(`/resources/images/${which}/${which}${i}`)
    // }
    this.setState({images: SHOWME[which], which})
    
  }
  
  navigateTo = (path) => {
    this.props.history.push(`/${path}`)
  }
  
  render() {
    return (
      <div className="photos--container">
        <div className="show-me--container">
          <h1 className="show-me">SHOW ME:</h1>
          <ShowMe isActive={this.state.which === "random"} onClick={this.changeTo} which="random" text="random"/>
          <ShowMe isActive={this.state.which === "dog"} onClick={this.changeTo} which="dog" text="dogs"/>
          <ShowMe isActive={this.state.which === "religious"} onClick={this.changeTo} which="religious"
                  text="religious things"/>
        </div>
        <Menu
          showHome={true}
          showPhotos={false}
          onClick={this.navigateTo}/>
        {this.state.images.map((id) => (
          <Image800
            pictureClassName="photo--instance"
            key={id}
            name={`${id}`}
          />
        ))}
      </div>
    )
  }
  
}

export default Photos