import React from 'react'
import videojs from 'video.js'
// import {Power2} from "gsap/TweenLite"
// import {TweenMax} from "gsap/TweenMax"
// import {TimelineMax} from "gsap/TimelineMax"
import "../styles/components/video.scss"

class VideoPlayer extends React.Component {
  
  // isPlaying = false
  
  
  constructor(props) {
    super(props)
    
    this.state = {isPlaying: false}
    // this.timeline = new TimelineMax({paused: true})
    // this.timeline = new TimelineMax()
    
    // this.onClose = this.onClose.bind(this)
  }
  
  componentDidMount() {
    // this.timeline
    //   .add(TweenMax.to(".video-js--top-container, .video-js--buttons-container", 0.5, {opacity: 0}))
    //   .add(TweenMax.to(".video-js--container", 0.2, {
    //     width: "100vw",
    //     height: '2px',
    //     borderWidth: 2,
    //     borderColor: "white",
    //     ease: Power2.easeOut,
    //     backgroundColor: "white"
    //   }))
    //   .add(TweenMax.to(".video-js--container", 0.2, {
    //     height: '0',
    //     width: '0',
    //     borderColor: "white",
    //     backgroundColor: "white"
    //   }))
    //
    // instantiate VideoPlayer.js
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
      console.log('onPlayerReady', this)
    })
    
    this.player.on(['waiting', 'pause'], () => {
      // this.isPlaying = false
      this.setState({isPlaying: false})
    })
    
    this.player.on('playing', () => {
      // this.isPlaying = true
      this.setState({isPlaying: true})
    })
    
    this.player.on('dblclick', () => {
      this.videoNode.requestFullscreen()
    })
  }
  
  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }
  
  play = () => {
    this.videoNode.play()
  }
  
  pause = () => {
    this.videoNode.pause()
  }
  
  toggle = () => {
    // this.isPlaying ? this.pause() : this.play()
    this.state.isPlaying ? this.pause() : this.play()
  }
  
  onClose() {
    this.props.navigateToHome()
    // this.timeline.play()
  }
  
  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <>
        <div className="video-js--top-container">
          <span onClick={() => this.onClose()}
                className="close">x</span>
          <h1>
            <span className="h1--fullscreen">GO FULLSCREEN</span>
            <span className="h1--volume">TURN UP THE VOLUME</span>
            <span className="h1--headphone">USE HEADPHONE</span>
          </h1>
        </div>
        <div className='video-js--container'>
          <div data-vjs-player>
            <video
              onClick={this.toggle}
              // controls={false}
              ref={node => this.videoNode = node}
              className="video-js"/>
          </div>
        </div>
        <div className="video-js--buttons-container">
          <span
            onClick={this.toggle}
            className={this.state.isPlaying ? "pause" : "play"}>{this.state.isPlaying ? "pause" : "play"}</span>
          <span
            onClick={() => (this.videoNode.requestFullscreen())}
            className="fullscreen">fullscreen</span>
        </div>
      </>
    )
  }
}

VideoPlayer.defaultProps = {
  autoplay: false,
  controls: false,
  preload: true,
  // userActions: {
  //   doubleClick: () => (
  //     this.videoNode.requestFullscreen()
  //   )
  // },
  poster: "/resources/otto-poster.jpg",
  sources: [{
    src: '/resources/video.mp4',
    type: 'video/mp4'
  }]
}

export default VideoPlayer