import React, {Component} from "react"
import Modal from "react-modal"

// const Image800 = ({name, pictureClassName = ""}) => (
class Image800 extends Component {
  
  state = {
    isOpened: false
  }
  
  openModal = () => {
    this.setState({
      isOpened: true
    })
  }
  
  closeModal = () => {
    this.setState({
      isOpened: false
    })
  }
  
  render() {
    return (
      <div className={this.props.pictureClassName}>
        <picture>
          <source
            media="(min-width: 601px)"
            srcSet={`${this.props.name}-md_1x.webp 1x, ${this.props.name}-md_2x.webp 2x`}
            type="image/webp"/>
          <source
            srcSet={`${this.props.name}-sm_1x.webp 1x, ${this.props.name}-sm_2x.webp 2x`}
            type="image/webp"
          />
          <img
            srcSet={`${this.props.name}-sm_1x.jpg 600w, ${this.props.name}-md_1x.jpg 900w`}
            src={`${this.props.name}-md_1x.jpg`}
            type="image/jpeg"
            alt=""/>
        </picture>
        <span
          onClick={this.openModal}
          className="picture--zoom">+</span>
        <Modal
          className="photo--modal"
          isOpen={this.state.isOpened}>
          <span
            onClick={this.closeModal}
            className="close--modal">X</span>
          <picture>
            <source
              srcSet={`${this.props.name}-lg_1x.webp 1x, ${this.props.name}-lg_2x.webp 2x`}
              type="image/webp"
            />
            <img
              alt=''
              src={`${this.props.name}-lg_1x.jpg`}/>
          </picture>
        </Modal>
      </div>
    )
  }
}

export default Image800