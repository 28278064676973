import React, {Component} from "react"
import "../styles/pages/about.scss"
import Menu from "../components/Menu"

class About extends Component {
  
  navigateTo = (path) => {
    this.props.history.push(`/${path}`)
  }
  
  
  render() {
    return (
      <>
        <div className="about--container">
          <Menu
            showHome={true}
            showAbout={false}
            onClick={this.navigateTo}
            goBack={this.goBack}
          />
          <div className="square red">
            <h3>The project</h3>
            <p>OTTO is Massimiliano’s flashback, a photo diary about what he saw, lived, felt and stolen.
              It is a eight months self portrait (2017), where time doesn’t count, everything gets melted and threw like a spin-dryer. A parallel world where words are not important.
              After Massimiliano’s exhibition we decided that a digital experience was mandatory.</p>
          </div>
          <div className="square green">
            <h3>The Photos</h3>
            <p>Massimiliano Gargano <br /> Class 1991, born in Naples, Italy. I am a mechanical engineer with a strong passion for arts and analogue photography. I love this chaos; the street culture and his shamelessness are always romantic to me.</p>
          </div>
          <div className="square blue">
            <h3>The Website</h3>
            <p>Stefano Imparato <br /> I'm the guy behind this digital experience. Video, photos, illustrations, music is what I love the most. I’m a developer, a designer, I basically do digital creative stuff. You can watch more of my work at stefanoimparato.com.</p>
          </div>
        </div>
      </>
    )
  }
}

export default About