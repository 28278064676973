import React, {Component} from "react"
import "../styles/pages/home.scss"
import {Linear} from "gsap/TweenLite"
import {TweenMax} from "gsap/TweenMax"
import {TimelineMax} from "gsap/TimelineMax"
import Image500 from "../components/Image500"
import Menu from "../components/Menu"
import {IMAGES} from "../constants"
import {randomIntFromInterval} from "../utils"

class Home extends Component {
  
  images = IMAGES
  
  componentDidMount() {
    const element = document.querySelectorAll(".home--image")
    
    this.imagesTimeline = new TimelineMax({
      paused: true,
      delay: 0.5,
      repeat: 1,
      // yoyo: true,
      onRepeat: () => {
        this.imagesTimeline.invalidate()
        this.imagesTimeline.add(TweenMax.staggerFromTo(element, 0.2, from, to, 0.1))
      }
    })
    
    const offset = 250
    const height = window.innerHeight
    const width = window.innerWidth - offset
    const from = {
      ease: Linear.easeIn,
      visibility: "hidden",
      cycle: {
        y: () => (randomIntFromInterval(-offset, height)),
        x: () => (randomIntFromInterval(-offset, width)),
      }
    }
    const to = {
      ease: Linear.easeIn,
      visibility: "visible",
    }
    
    this.imagesTimeline.add(TweenMax.staggerFromTo(element, 0.2, from, to, 0.1))
    this.imagesTimeline.play()
  }
  
  navigateTo = (path) => {
    this.props.history.push(`/${path}`)
  }
  
  render() {
    return (
      <div className="home--container">
        <div className="images--container">
          {this.images.map((id) => (
            <Image500
              pictureClassName="home--image"
              key={id}
              name={`${id}`}/>
          ))}
        </div>
        <Menu onClick={this.navigateTo}/>
        <div className="logo--container">
          <h1>OTTO</h1>
        </div>
        {/*<div className="cta--container">*/}
          {/*<WatchTheVideo*/}
            {/*navigateToVideo={() => this.navigateTo('otto')}/>*/}
        {/*</div>*/}
      </div>
    )
  }
}

export default Home