import React from "react"
import MenuVoice from "../components/MenuVoice"
import WatchTheVideo from "./WatchTheVideo"

const Menu = ({showHome = false, showAbout = true, showPhotos = true, onClick}) => (
  <div className="menu--container">
    {showHome && <MenuVoice onClick={() => (onClick(''))} text="home" className={'left'}/>}
    <WatchTheVideo onClick={() => onClick('otto')}/>
    {showAbout && <MenuVoice onClick={() => (onClick('about'))} text="about" className={showHome ? 'right' : 'left'}/>}
    {showPhotos && <MenuVoice onClick={() => (onClick('photos'))} text="photos" className={'right'}/>}
  </div>
)

export default Menu