import React, {Component} from 'react'
import {BrowserRouter as Router, Route} from "react-router-dom"
import Modal from "react-modal"
import Home from "./pages/Home"
import Video from "./pages/Video"
import Photos from "./pages/Photos"
import About from "./pages/About"

Modal.setAppElement('#root')

class App extends Component {
  
  render() {
    return (
      <Router>
        <>
          <Route path="/" exact component={Home}/>
          <Route path="/otto" exact component={Video}/>
          <Route path="/photos" exact component={Photos}/>
          <Route path="/about" exact component={About}/>
        </>
      </Router>
    )
  }
}

export default App
