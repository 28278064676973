import React, {Component} from "react"
import "../styles/components/menu-voice.scss"
import {arrayRotateOne} from "../utils"

class MenuVoice extends Component {
  
  state = {
    classNames: ["first-color", "second-color", "third-color"]
  }
  
  onMouseover = () => {
    this.timer = setInterval(() => (
      this.setState({
        classNames: arrayRotateOne(this.state.classNames)
      })
    ), 50)
  }
  
  onMouseout = () => (
    clearInterval(this.timer)
  )
  
  componentWillUnmount() {
    clearInterval(this.timer)
  }
  
  render() {
    
    return (
      <div
        onMouseOver={this.onMouseover}
        onMouseOut={this.onMouseout}
        onClick={this.props.onClick}
        className={`menu-voice menu-voice--${this.props.className}`}>
        <h1 className={`menu-voice--first ${this.state.classNames[0]}`}>{this.props.text}</h1>
        <h1 className={`menu-voice--second ${this.state.classNames[1]}`}>{this.props.text}</h1>
        <h1 className={`menu-voice--third ${this.state.classNames[2]}`}>{this.props.text}</h1>
      </div>
    )
  }
  
}

export default MenuVoice
