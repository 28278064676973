export const PATH = "/resources/images"

export const IMAGES = ["099", "103", "113", "116", "198", "265", "369", "383", "392", "394"].map((val) => (`${PATH}/img${val}`))

const RANDOM = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((val) => (`${PATH}/random/random${val}`))
const DOG = [0, 1, 2, 3, 4, 5, 6, 7, 8].map((val) => (`${PATH}/dog/dog${val}`))
const RELIGIOUS = [0, 1, 2, 3].map((val) => (`${PATH}/religious/religious${val}`))
export const SHOWME = {
  dog: DOG,
  religious: RELIGIOUS,
  random: RANDOM
}
