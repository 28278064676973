import React, {Component} from "react"
import "../styles/components/watch-the-video.scss"
import {arrayRotateOne} from "../utils"

class WatchTheVideo extends Component {
  
  state = {
    classNames: ["white", "green", "red"]
  }
  
  onMouseover = () => {
    this.timer = setInterval(() => (
      this.setState({
        classNames: arrayRotateOne(this.state.classNames, true)
      })
    ), 50)
  }
  
  onMouseout = () => (
    clearInterval(this.timer)
  )
  
  componentWillUnmount() {
    clearInterval(this.timer)
  }
  
  render() {
    return (
      <div
        onMouseOver={this.onMouseover}
        onMouseOut={this.onMouseout}
        className="watch-the-video"
        onClick={this.props.onClick}>
        <h1 className={`first ${this.state.classNames[0]}`}>!WATCH THE VIDEO!</h1>
        <h1 className={`second ${this.state.classNames[1]}`}>!WATCH THE VIDEO!</h1>
        <h1 className={`third ${this.state.classNames[2]}`}>!WATCH THE VIDEO!</h1>
      </div>
    )
  }
  
}

export default WatchTheVideo