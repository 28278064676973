import React from "react"

const Image500 = ({name, pictureClassName = ""}) => (
  <picture className={pictureClassName}>
    <source
      srcSet={`${name}-sm_1x.webp 1x, ${name}-sm_2x.webp 2x`}
      type="image/webp"/>
    <img
      src={`${name}-sm_1x.jpg`}
      type="image/jpeg"
      alt=""/>
  </picture>
)

export default Image500