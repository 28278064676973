import React from "react"
import VideoPlayer from "../components/VideoPlayer"
import "video.js/dist/video-js.min.css"

const Video = (props) => {
  
  const navigateBack = () => {
    // props.history.push(`/`)
    props.history.goBack()
  }
  
  return (
    <div className="video-player--wrapper">
      <VideoPlayer
        navigateToHome={navigateBack}/>
    </div>
  )
}

export default Video